import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { GoogleConnectButton } from './GoogleConnectButton';
import { FacebookConnectButton } from './FacebookConnectButton';
import { AppleConnectButton } from './AppleConnectButton';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { useStore } from '@cobuildlab/react-simple-state';
import { providerConnectStoreAction } from '../setting-actions';
import { providerConnectStore } from '../setting-events';
import { useDisconnectUserProviderMutation } from '../../../shared/types/generated';

type ProfileRowProps = {
  label?: string;
  value?: string;
};

const ProfileRow: React.FC<ProfileRowProps> = ({ label, value }) => {
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={5} md={3}>
        <Typography variant="subtitle1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7} md={9}>
        <Typography variant="subtitle1">{value}</Typography>
      </Grid>
    </Grid>
  );
};
export const SettingAccount: React.FC = () => {
  const { provider: socialProvider, refetch, user } = useAuth();
  const { isOpen, provider } = useStore(providerConnectStore);

  const [callDeleteAction, { loading }] = useDisconnectUserProviderMutation({
    onCompleted: () => {
      refetch();
      providerConnectStoreAction(false, undefined);
    },
  });

  return (
    <>
      <Box>
        <ProfileRow label="Nombre de usuario" value={user?.name} />
        <ProfileRow label="Email" value={user?.email} />
        {socialProvider && (
          <ProfileRow
            label="Conectado con"
            value={`Cuenta ${socialProvider}`}
          />
        )}
      </Box>
      <Grid container columnGap={'5'} mt={'30px'}>
        <Grid item xs={12} sm={3}>
          <Typography fontWeight={'600'}>Conectado con</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GoogleConnectButton />
            </Grid>
            <Grid item xs={12}>
              <FacebookConnectButton />
            </Grid>
            <Grid item xs={12}>
              <AppleConnectButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogDefault
        isOpen={isOpen}
        handleClose={() => providerConnectStoreAction(false, undefined)}
        title="Desconectar"
        isLoading={loading}
        showActions
        handleSuccess={() =>
          callDeleteAction({
            variables: {
              data: {
                provider,
              },
            },
          })
        }
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          {`¿Estas seguro que deseas desconectar tu cuenta de ${provider?.toLowerCase()}?`}
        </div>
      </DialogDefault>
    </>
  );
};
