import { Plan } from '../../shared/types/generated';

export const getSubscriptionPlans = (
  plans: Plan[] | undefined | null,
): {
  item1: Plan | undefined;
  item2: Plan | undefined;
  item3: Plan | undefined;
} => {
  if (plans) {
    const [item1, item2, item3] = plans;
    return {
      item1,
      item2,
      item3,
    };
  }

  return {
    item1: undefined,
    item2: undefined,
    item3: undefined,
  };
};
