import { User } from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {
  let roleName = user?.role?.name as string;

  if (!roleName) {
    roleName = GUEST_ROLE;
  }

  return !!RBACSchema.find(
    (schema) =>
      schema.Role === roleName && schema.Permissions.includes(permission),
  );
};
