import React, { ChangeEvent, useState } from 'react';
import {
  Education,
  Gender,
  UpdateUserMutationVariables,
  User,
} from '../../../shared/types/generated';
import { useAuth } from '../../auth/auth-hook';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { updateUserAction } from '../../user/user-actions';
import { openDialogAlert } from '../../alert/alert-actions';
import { Box, Grid, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { USER_EDUCATION_LIST, USER_GENDER_LIST } from '../setting-utils';
import { SelectDefault } from '../../../shared/components/input/SelectDefault';
import { changeAvatarStore } from '../setting-events';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
  }),
);

const StyledButton = styled(ButtonDefault)({
  backgroundColor: PRIMARY_COLOR,
  border: 'none!important',
  color: 'white!important',
  width: 200,
});

export const SettingAccountForm: React.FC = () => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const { file } = useStore(changeAvatarStore);
  const [userData, setUserData] = useState<User>({
    id: 0,
    name: '',
    password: '',
    gender: Gender.Female,
    email: '',
    birthday: '',
    ...user,
  });
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [updateUser, loading] = useCallAction(updateUserAction, {
    onCompleted: () => {
      refetch();
      return openDialogAlert('Se ha actualizado tu perfil correctamente.');
    },
    onError: (error) => {
      return openDialogAlert(error.message);
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setUserData({
      ...userData,
      [event.target.name as keyof User]: event.target.value as string,
    });
  };

  const handleUpdate = (): void => {
    if (userData.password && userData.password !== repeatPassword) {
      return openDialogAlert('Las contraseñas deben coincidir');
    }

    const { id, name, password, gender, birthday, email, education } = userData;
    let variables: UpdateUserMutationVariables = {
      data: {
        user: {
          id,
          name,
          gender,
          birthday,
          email,
          education,
        },
      },
    };

    if (password) {
      variables = {
        data: {
          user: { ...(variables.data?.user as User), password },
        },
      };
    }

    if (file) {
      variables = {
        data: {
          ...variables.data,
          file,
        },
      };
    }

    updateUser(variables);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          placeholder={'Nombre de usuario'}
          name="name"
          fullWidth
          value={decodeURI(userData.name)}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          placeholder={'Email'}
          name="email"
          fullWidth
          value={userData?.email}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          disabled={!!user?.email}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          placeholder="Contraseña"
          name="password"
          type="password"
          fullWidth
          value={userData.password as string}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          placeholder="Repetir Contraseña"
          name="repeatPassword"
          type="password"
          fullWidth
          value={repeatPassword}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => {
            setRepeatPassword(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectDefault
          placeholder="Sexo"
          variant="outlined"
          options={USER_GENDER_LIST}
          name={'gender'}
          defaultValue={user?.gender}
          InputProps={{
            className: classes.input,
          }}
          onChangeData={(field, value) => {
            setUserData({
              ...userData,
              [field]: value as Gender,
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          placeholder="Fecha de nacimiento"
          name="birthday"
          type="date"
          fullWidth
          value={userData?.birthday}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => {
            setUserData({
              ...userData,
              birthday: event.target.value as Gender,
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectDefault
          placeholder="Escolaridad"
          variant="outlined"
          options={USER_EDUCATION_LIST}
          name={'education'}
          value={userData?.education}
          InputProps={{
            className: classes.input,
          }}
          onChangeData={(field, value) => {
            setUserData({
              ...userData,
              [field]: value as Education,
            });
          }}
        />
      </Grid>
      <Grid xs={12}>
        <Box
          my={'30px'}
          alignItems={'center'}
          justifyContent={'center'}
          display={'flex'}
          width={'full'}
        >
          <StyledButton
            isLoading={loading}
            style={{ width: 200, height: 45 }}
            onClick={() => handleUpdate()}
          >
            Guardar cambios
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};
