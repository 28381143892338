import { Gender, Education } from '../../shared/types/generated';

export const USER_GENDER_LIST = [
  { label: 'Masculino', value: Gender.Male },
  { label: 'Femenino', value: Gender.Female },
  { label: 'No declara', value: Gender.NoApply },
];

export const USER_EDUCATION_LIST = [
  { label: 'Primaria', value: Education.Primary },
  { label: 'Secundaria', value: Education.Secondary },
  { label: 'Superior', value: Education.Superior },
  { label: 'Postgrado', value: Education.Graduate },
];
