import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
      placeItems: 'center',
      position: 'relative',
    },
    forceCentering: {
      margin: 'auto auto',
    },
    loadingGiftContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      placeItems: 'center',
      textAlign: 'center',
      paddingBottom: '20px',
      position: 'relative',
    },
  }),
);
type PlanLoadingProps = {
  containerClassName?: string;
};
export const PaymentMethodLoader: React.FC<PlanLoadingProps> = ({
  containerClassName,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={containerClassName ? containerClassName : classes.container}
      >
        <Box className={classes.forceCentering}>
          <div className={classes.loadingGiftContainer}>
            <img src="/loading.gif" alt="loader-gif" width={200} height={200} />
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                position: 'absolute',
                left: 0,
                bottom: 70,
              }}
            >
              Cargando ...
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};
