import moment from 'moment/moment';

export const AUTH_EMAIL = 'AUTH_EMAIL';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_HOME = 'AUTH_HOME';

export const AUTH_PASSWORD_EMAIL_SEND = 'AUTH_PASSWORD_EMAIL_SEND';

export const AUTH_FORGOT_PASSWORD_NOT_FOUND = 'AUTH_FORGOT_PASSWORD_NOT_FOUND';
export const FACEBOOK_CALLBACK = process.env.REACT_APP_FACEBOOK_CALLBACK;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const TARGET_ORIGIN_URL = process.env.REACT_APP_TARGET_ORIGIN_URL || '';
export const SITE_URL = process.env.REACT_APP_SITE_URL || '';
export const APP_ENV = process.env.REACT_APP_ENV || 'local';
export const REACT_APP_BACKEND_PATH = process.env.REACT_APP_BACKEND_PATH || '';

export const ANALYTICS_ENDPOINT =
  process.env.REACT_APP_ANALYTICS_ENDPOINT || '';
export const DOMAIN_SITE = `.${process.env.REACT_APP_DOMAIN || ''}`;

export const AUTH_RESET_PASSWORD_DONE = 'AUTH_RESET_PASSWORD_DONE';

export const COOKIES_OPTIONS = {
  path: '/',
  domain: DOMAIN_SITE,
};

export const ANALYTICS_COOKIES_OPTIONS = {
  expires: moment().add(1, 'day').toDate(),
  path: '/',
  domain: DOMAIN_SITE,
};

export const USER_COOKIE = '_u';

export enum PaymentSelection {
  OpenPay = 'OPEN_PAY',
  PayPal = 'PAYPAL',
}

export const GUEST_ROLE = 'GUEST';

export const APP_ENDPOINT = `${process.env.REACT_APP_ENDPOINT || ''}`;
