import { OpenPayCreditCard } from './open-pay-types';

export const createOpenPayInstance = (): void => {
  /**
   * PRUEBAS
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setId('mwjyc6ye328mekw97lit');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setApiKey('pk_62bc2a9f3c6c4efa829a1c00b3aac101');

  /**
   * PRODUCCIÓN
   */
  // eslint-disable-next-line no-undef
  // OpenPay.setId('m3cv4ahm37lxgfsxk2sp');
  // // eslint-disable-next-line no-undef
  // OpenPay.setApiKey('pk_9035fff184164bf9a4b0eb0ab6c614af');

  /**
   * ACTIVAR MODO DE PRUEBAS
   * TRUE: ACTIVADO EL MODO DE PRUEBAS
   * FALSE: DESACTIVAR MODO DE PRUEBAS
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.setSandboxMode(true);
};

export const createOpenPaySubscription = async (
  card: OpenPayCreditCard,
  onSuccess: (data: { deviceDataId: string; token: string }) => void,
  onError: () => void,
): Promise<void> => {
  createOpenPayInstance();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  OpenPay.token.create(
    card,
    async (response: {
      data: {
        card: OpenPayCreditCard;
        id: string;
      };
      status: number;
    }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const deviceDataId = OpenPay.deviceData.setup();

      onSuccess({ deviceDataId, token: response.data.id as string });
    },
    async () => {
      onError();
    },
  );
};
