import styled from '@emotion/styled';
import { Box, Switch, SwitchProps, Typography } from '@mui/material';
import React, { useState } from 'react';
//import { useMailchimpAddSubscriptionMutation } from '../../../shared/types/generated';
import { useAuth } from '../../auth/auth-hook';
//import { openDialogAlert } from '../../alert/alert-actions';

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 63,
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    height: 38,
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'white',
        border: '1px solid black',
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        color: 'black',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
      height: '20px !important',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: '15px !important',
    backgroundColor: '#E9E9EA',
    border: '1px solid #999999',
    height: 24,
    width: 45,
    opacity: 1,
    position: 'absolute',
    bottom: 6,
  },
}));
type NewsletterOptionProps = {
  name: string;
  description: string;
  audience: string;
  isSelected: boolean;
  isSubscribed: boolean;
  onSuccess: () => void;
};

export const NewsletterOption: React.FC<NewsletterOptionProps> = ({
  name,
  description,
  isSelected,
  onSuccess,
  isSubscribed,
  audience,
}) => {
  const { user } = useAuth();

  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;
  console.log(isSelected, audience);
  const [select, setSelect] = useState(false);
  return (
    <div>
      <Box
        border={`${isSelected ? '1px solid black' : '1px solid white'}`}
        component={'div'}
        style={{
          boxShadow: '0px 3px 6px #00000029',
          padding: '20px 20px',
          borderRadius: '10px',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography fontSize={24} fontWeight={700}>
            {name}
          </Typography>
          <CustomSwitch
            onChange={() => {
              console.log(isSubscribed && !subscription);
              {
                /**if (isSubscribed && !subscription) openDialogAlert('Debes tener una suscripción  para poder suscribirte al newsletter');

              else {
               onSuccess();
              } */
              }
              setSelect(!select);
              onSuccess();
            }}
            checked={select}
          />
        </Box>
        <Typography color="#9CA5AC" mt={3}>
          {description}
        </Typography>
      </Box>
    </div>
  );
};
