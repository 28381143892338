import { useContext, useEffect, useState } from 'react';
import { DefaultValuesContextType, Context } from './AuthContext';
import { USER_COOKIE } from '../../shared/constans';
import { useNavigate } from 'react-router-dom';
import { universalCookies } from '../../shared/cookies';

export const useAuth = (): DefaultValuesContextType => {
  const { isAuthenticated, isLoading, user, refetch, provider } =
    useContext(Context);
  return {
    isAuthenticated,
    isLoading,
    user,
    refetch,
    provider,
  };
};

export const useAuthRedirect = (
  redirectTo: string,
): { isAuthenticated: boolean } => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    if (universalCookies.get(USER_COOKIE)) {
      setIsAuthenticated(true);
      navigate(redirectTo);
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate, redirectTo]);

  return {
    isAuthenticated,
  };
};
