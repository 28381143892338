import { createStyles, makeStyles } from '@mui/styles';
import { Box, Grid, LinearProgress, Theme, Typography } from '@mui/material';
import React from 'react';

import { PaymentMethodLoader } from './PaymentMethodLoader';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    defaultsizes: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
      placeItems: 'center',
      position: 'relative',
      minHeight: '350px',
    },
    defaultStylesTextLoader: {
      background: '#fff',
      color: '#777',
      borderRadius: '5px',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #999999',
      color: '#999999',
    },
    subtitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    box: {
      borderRadius: 5,
      boxShadow: '0px 3px 25px #00000012',
      justifyContent: 'center',
      border: '1px solid #999999',
      padding: '20px 30px',
    },
    cartText: { fontSize: 15, lineHeight: 15, marginLeft: 10 },
  }),
);

export const PaymentMethodCardLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.box} mb={2}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.title} mb={2}>
              <Typography fontSize={18}>Información detallada</Typography>
              <Typography fontSize={18}>Total</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.subtitle}>
              <>
                <LinearProgress
                  color="inherit"
                  className={classes.defaultStylesTextLoader}
                  style={{ width: '155px', height: '40px' }}
                />
              </>
              <>
                <LinearProgress
                  color="inherit"
                  className={classes.defaultStylesTextLoader}
                  style={{ width: '78px', height: '28px' }}
                />
              </>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <PaymentMethodLoader containerClassName={classes.defaultsizes} />
      </Box>
    </>
  );
};
