import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { MainLoader } from '../../../shared/components/MainLoader';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    container: {
      border: '1px solid #000',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
      placeItems: 'center',
      position: 'relative',
      '&::before': {
        background: 'white!important',
      },
    },
  }),
);
type PlanLoadingProps = {
  containerClassName?: string;
};
export const PlanLoading: React.FC<PlanLoadingProps> = ({
  containerClassName,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box
        className={containerClassName ? containerClassName : classes.container}
      >
        <MainLoader height="500px">Cargando...</MainLoader>
      </Box>
    </>
  );
};
