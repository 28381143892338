import React from 'react';
import { Box, Container, Grid, Hidden } from '@mui/material';
import { Slider } from '../../../shared/components/slider/Slider';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../../shared/types/generated';
import { getSubscriptionPlans } from '../plan-utils';
import { PlanItemOne } from './PlanItemOne';
import { PlanItemTwo } from './PlanItemTwo';
import { PlanItemThree } from './PlanItemThree';

export const PlanListItem: React.FC = () => {
  const { data, loading } = usePlansQuery({
    variables: {
      where: {
        status: PaypalStatuses.Active,
      },
    },
  });

  const { item1, item2, item3 } = getSubscriptionPlans(
    (data?.plans?.items || []) as Plan[],
  );

  const items = [
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <PlanItemOne plan={item1} loading={loading} />
    </Grid>,
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <PlanItemTwo plan={item2} loading={loading} />
    </Grid>,
    <Grid item xs={12} sm={6} lg={4} my={'20px'}>
      <PlanItemThree plan={item3} loading={loading} />
    </Grid>,
  ];
  return (
    <Container style={{ marginBottom: '15px' }}>
      <Hidden smDown={true} smUp={false}>
        <Box>
          <Grid
            container
            spacing={'20px'}
            maxWidth={'1080px'}
            margin={'0 auto'}
          >
            {items.map((item) => (
              <>{item}</>
            ))}
          </Grid>
        </Box>
      </Hidden>
      <Hidden smDown={false} smUp={true}>
        <Box
          display={'relative'}
          margin={'0 auto'}
          justifyContent={'center'}
          maxWidth={'350px'}
        >
          <Slider items={items} controls infinite />
        </Box>
      </Hidden>
    </Container>
  );
};
