import React, { ReactNode } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Plan } from '../../../shared/types/generated';

type PlanItemProps = {
  buttonClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  priceClassName?: string;
  containerClassName?: string;
  textButton?: string;
  descriptionClassName?: string;
  helpTextContent?: string | ReactNode;
  priceText?: string;
  plan?: Plan;
  onClick: () => void;
};

export const PlanItem: React.FC<PlanItemProps> = ({
  buttonClassName,
  plan,
  titleClassName,
  subTitleClassName,
  priceClassName,
  descriptionClassName,
  containerClassName,
  helpTextContent,
  textButton,
  priceText = '',
  onClick,
}) => {
  return (
    <Box className={containerClassName}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={titleClassName}>
            <Typography>{plan?.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={subTitleClassName}>
            <Typography>{plan?.sub_title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={priceClassName}>
            <Typography fontSize={'44px'} fontWeight={'700'}>
              ${plan?.price}
            </Typography>
            {priceText && (
              <Typography width={'55px'} paddingLeft={1} fontSize={'12px'}>
                {priceText}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={descriptionClassName}>
            <Typography>{plan?.description}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={buttonClassName}>
            <Button onClick={() => onClick()}>{textButton}</Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {helpTextContent}
      </Grid>
    </Box>
  );
};
