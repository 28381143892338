import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useAuth } from '../../../../modules/auth/auth-hook';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from '../../../css/theme';
import { RoleAuthorization } from '../../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../../modules/rbac/rbac-types';
import { openMobileMenu } from './user-actions';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_ENDPOINT } from '../../../constans';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '18px!important',
      marginLeft: '5px!important',
    },
    textRed: {
      color: PRIMARY_COLOR,
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        component: 'div',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
  }),
);
type HeaderProps = {
  showSidebar?: boolean;
};
export const Header: React.FC<HeaderProps> = ({ showSidebar }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoClasses}>
            <Hidden mdDown={false} mdUp={true}>
              <a href={APP_ENDPOINT}>
                <img
                  src="/LOGO_DIGITAL.svg"
                  alt="logo"
                  width={150}
                  height={40}
                  style={{ flexGrow: 1, objectFit: 'contain' }}
                />
              </a>
            </Hidden>
            <Hidden mdDown={true} mdUp={false}>
              <a href={APP_ENDPOINT}>
                <img
                  src="/LOGO_DIGITAL.png"
                  alt="logo"
                  width={200}
                  height={50}
                  style={{ flexGrow: 1, objectFit: 'contain' }}
                />
              </a>
            </Hidden>
          </Box>
          {showSidebar && (
            <Hidden mdDown={false} mdUp={true}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => openMobileMenu()}
                edge="start"
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon style={{ color: 'black' }} />
              </IconButton>
            </Hidden>
          )}
          <Box
            display={'flex'}
            justifyContent={'end'}
            width={'100%'}
            alignItems={'center'}
          >
            <Hidden lgDown={true} lgUp={false}>
              <RoleAuthorization
                render={() => (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{
                      textTransform: 'capitalize',
                      height: 45,
                      margin: 'none !important',
                    }}
                    onClick={() => navigate('/planes')}
                  >
                    <Typography>
                      <Hidden lgDown={true} lgUp={false}>
                        Quiero{' '}
                      </Hidden>
                      acceso total
                    </Typography>
                  </Button>
                )}
                permission={RBACPermissions.CAN_SUBSCRIPTION}
                error={() => null}
              />
            </Hidden>

            <div className={classes.menu}>
              <Hidden smDown={true} smUp={false}>
                <div>
                  {decodeURI(user?.name || '') || decodeURI(user?.email || '')}
                </div>
              </Hidden>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={user?.avatar as string}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <ListItemText>
                    Usuario:{' '}
                    <span className={classes.textRed}>
                      {decodeURI(user?.name as string)}
                    </span>{' '}
                  </ListItemText>
                </MenuItem>
                <div className={classes.groupButton}>
                  <MenuItem onClick={() => navigate('/')}> Mi perfil</MenuItem>
                  <MenuItem>Newsletter</MenuItem>
                  <MenuItem>
                    <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      href="https://api.whatsapp.com/send/?phone=528443800838&text=Hola.+Me+estoy+contactando+desde+el+portal+de+https://vanguardia.com.mx/&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      Mensaje de whatsapp
                    </a>
                  </MenuItem>
                </div>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PowerSettingsNewSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={() => navigate('/logout')}>
                    Cerrar sesión
                  </ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
