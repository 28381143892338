import React from 'react';
import {
  PayPalButtons,
  PayPalScriptProvider,
  FUNDING,
} from '@paypal/react-paypal-js';
import { useAuth } from '../auth/auth-hook';
import { useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { openDialogAlert } from '../alert/alert-actions';
import { useCreatePaypalSubscriptionMutation } from '../../shared/types/generated';
import { Typography } from '@mui/material';
import { SITE_URL } from '../../shared/constans';

const PaypalButton: React.FC = () => {
  const { user } = useAuth();
  const { plan } = useStore(changePlanStore);

  const [callSubscriptionAction] = useCreatePaypalSubscriptionMutation({
    onCompleted: () => {
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      window.location.href = SITE_URL;
    },
    onError: (error) => {
      openDialogAlert(error.message);
    },
  });

  return plan && plan.paypal ? (
    <PayPalButtons
      fundingSource={FUNDING.PAYPAL}
      createSubscription={async (data, actions) => {
        return actions.subscription
          .create({
            plan_id: plan?.paypal?.paypal_id as string,
            subscriber: {
              email_address: user?.email,
            },
            custom_id: user?.email,
          })
          .then((orderId) => {
            return orderId;
          });
      }}
      style={{
        label: 'subscribe',
      }}
      onApprove={async (data) => {
        {
          const { subscriptionID, orderID } = data;
          console.log({ data });
          callSubscriptionAction({
            variables: {
              data: {
                planId: plan?.id,
                subscriptionId: subscriptionID,
                orderId: orderID,
              },
            },
          });
        }
      }}
      onError={() => {
        return openDialogAlert(
          'Tenemos problemas para realizar la suscripción',
        );
      }}
    />
  ) : null;
};

export const Paypal: React.FC = () => {
  const { plan } = useStore(changePlanStore);

  if (!plan?.paypal)
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Paypal no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  return (
    <PayPalScriptProvider
      options={{
        clientId:
          'AcYWdG8gPY75Gy3Ru8kLLWXx2mqtfoIvPvgI4v51Idkf_sZb7dPYVriQcyUr9sI8RANpyRUOhbpd7o4O',
        components: 'buttons',
        intent: 'subscription',
        vault: true,
      }}
    >
      <PaypalButton />
    </PayPalScriptProvider>
  );
};
