import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../shared/constans';
import { useCookies } from 'react-cookie';
import { useFacebookSignupMutation } from '../../shared/types/generated';
import { redirectToHome } from '../../shared/utils';

export const AuthFacebookCallback: React.FC = () => {
  const url: URL = new URL(window.location.href.replace('#', ''));
  const params: URLSearchParams = url.searchParams;
  const accessToken = params.has('access_token')
    ? params.get('access_token')
    : '';
  const redirectTo = params.has('state')
    ? (params.get('state') as string)
    : '/';
  const [, setCookie] = useCookies([USER_COOKIE]);

  const [facebookSignupCallback] = useFacebookSignupMutation({
    onCompleted: ({ facebookSignup }) => {
      setCookie(USER_COOKIE, facebookSignup?.token, COOKIES_OPTIONS);
      redirectToHome('/');
    },
    onError: () => {
      window.location.href = `${url}?error=Tenemos problemas para identificar el usuario facebook&form=HEADER`;
    },
  });

  useEffect(() => {
    if (accessToken) {
      facebookSignupCallback({
        variables: {
          token: accessToken,
          url: redirectTo,
        },
      });
    }
  }, [accessToken, facebookSignupCallback, redirectTo]);

  return (
    <Box
      sx={{ display: 'flex' }}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      flexDirection="column"
    >
      <img alt="loading gif" src={'/loading.gif'} width={500} />
    </Box>
  );
};
