import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { FACEBOOK_APP_ID, FACEBOOK_CALLBACK } from '../../../shared/constans';
import MobileDetect from 'mobile-detect';
import { getFacebookState } from '../../auth/auth-utils';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  SocialProviders,
  useConnectUserFacebookMutation,
} from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import FacebookIcon from '@mui/icons-material/Facebook';
import { providerConnectStoreAction } from '../setting-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    connect: {
      background: `${PRIMARY_COLOR}!important`,
      color: 'white!important',
      width: '130px',
      height: 25,
    },
    disconnect: {
      background: '#efefef!important',
      color: `${PRIMARY_COLOR}!important`,
      border: '1px solid #FA0000',
      width: '130px',
      height: 25,
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    disable: {
      background: '#efefef!important',
      opacity: '65% !important',
    },
  }),
);

export const FacebookConnectButton: React.FC = () => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const agent = new MobileDetect(window.navigator.userAgent);

  const isConnected = user?.social_providers?.find(
    (socialProvider) => socialProvider?.provider === SocialProviders.Facebook,
  );

  console.log({ isConnected });

  const [callbackAction, { loading }] = useConnectUserFacebookMutation({
    onCompleted: () => {
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de facebook correctamente',
      );
    },
    onError: (error) => {
      return openDialogAlert(error.message);
    },
  });

  const handleDisconnect = (): void => {
    if (isConnected?.primary)
      return openDialogAlert('No se puede desconectar su cuenta principal');

    providerConnectStoreAction(true, SocialProviders.Facebook);
  };

  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    callbackAction({
      variables: {
        data: {
          token: userInfo.accessToken,
        },
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={6} sm={8} alignItems="center">
        <Grid container height={'100%'}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <FacebookIcon />
            <Typography paddingLeft={2}>Facebook</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            paddingTop={1}
            display="flex"
            alignItems="center"
          >
            {isConnected ? (
              <Box display={'flex'} alignItems={'center'}>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  style={{ color: 'green' }}
                />
                <Box style={{ color: 'green' }} className={classes.textSocial}>
                  Conectada
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {agent.mobile() && agent.phone() ? (
          <FacebookLogin
            appId={FACEBOOK_APP_ID as string}
            callback={responseFacebook}
            fields="name,email,picture"
            redirectUri={`${FACEBOOK_CALLBACK}/callback/facebook`}
            responseType="token"
            state={getFacebookState()}
            isMobile
            render={(renderProps) => {
              return (
                <ButtonDefault
                  isLoading={loading}
                  className={
                    !isConnected ? classes.connect : classes.disconnect
                  }
                  variant="outlined"
                  style={{ width: 160 }}
                  onClick={() =>
                    !isConnected ? renderProps.onClick() : handleDisconnect()
                  }
                >
                  {!isConnected ? 'Conectar' : 'desconectar'}
                </ButtonDefault>
              );
            }}
          />
        ) : (
          <FacebookLogin
            appId={FACEBOOK_APP_ID as string}
            callback={responseFacebook}
            fields="name,email,picture"
            isMobile={false}
            autoLoad={false}
            scope="public_profile,email"
            render={(renderProps) => {
              return (
                <ButtonDefault
                  isLoading={loading}
                  variant="outlined"
                  className={
                    !isConnected ? classes.connect : classes.disconnect
                  }
                  style={{ width: 160 }}
                  onClick={() =>
                    !isConnected ? renderProps.onClick() : handleDisconnect()
                  }
                >
                  {!isConnected ? 'Conectar' : 'desconectar'}
                </ButtonDefault>
              );
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
