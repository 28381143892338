import React from 'react';
import { Box } from '@mui/material';
import { APP_ENDPOINT } from '../../../constans';

export const HeaderAuth: React.FC = () => {
  return (
    <Box
      style={{ background: 'white' }}
      height={'75px'}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      width={'100%'}
    >
      <a href={APP_ENDPOINT}>
        <img
          src="/LOGO_DIGITAL.png"
          alt="logo"
          width={225}
          height={40}
          style={{ flexGrow: 1, objectFit: 'contain' }}
        />
      </a>
    </Box>
  );
};
