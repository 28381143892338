import React from 'react';
import { useStore } from '@cobuildlab/react-simple-state';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme,
  Button,
} from '@mui/material';
import { dialogAlertStore } from './alert-events';
import { closeDialogAlert } from './alert-actions';
import { createStyles, makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../shared/css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    button: {
      borderRadius: 5,
      background: `${PRIMARY_COLOR}!important`,
      color: 'white!important',
      width: '180px',
      justifyContent: 'center',
      margin: 'auto auto !important',
    },
    dialogContentText: {
      fontSize: 20,
    },
    positionCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    content: {
      width: '300px',
      margin: '0 auto',
      textAlign: 'center',
    },
  }),
);
export const DialogAlert: React.FC = () => {
  const { isOpen, message, extraAction } = useStore(dialogAlertStore);
  const classes = useStyles();

  return (
    <Dialog onClose={() => closeDialogAlert()} open={isOpen}>
      <DialogContent className={classes.content}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentText}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.positionCenter}>
        <Button
          className={classes.button}
          onClick={() => {
            if (extraAction) extraAction();
            closeDialogAlert();
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
