import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
  Hidden,
  Drawer,
  Button,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { SETTING_ROUTES } from './layout-utils';
import { PRIMARY_COLOR } from '../../../css/theme';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { closeMobileMenu } from './user-actions';
import { RoleAuthorization } from '../../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../../modules/rbac/rbac-types';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: PRIMARY_COLOR,
    },
    listItem: {
      '& > span': {
        fontWeight: 'bold',
      },
    },
    helpCenter: {
      color: 'black',
      '&:hover': {
        color: '#0080ff',
      },
    },
  }),
);

export const Menu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  return (
    <>
      <Hidden mdDown={false} mdUp={true}>
        <Drawer anchor="left" open={open} onClose={() => closeMobileMenu()}>
          <Box sx={{ width: 250 }} onClick={() => closeMobileMenu()}>
            <ListItem divider>
              <Typography color="primary" fontWeight="bold">
                TU CONTENIDO
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route) => {
              return (
                <ListItem
                  divider
                  style={{ cursor: 'pointer' }}
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <ListItemText primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}

            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                <Typography fontWeight="bold">SOPORTE</Typography>
              </ListItem>
              <Divider />
              <a
                className={classes.helpCenter}
                style={{ textDecoration: 'none' }}
                target="_blank"
                href=""
              >
                <ListItem divider>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Centro de ayuda"
                  />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              </a>
            </List>
            <List component="nav" aria-label="mailbox folders">
              <ListItem>
                <RoleAuthorization
                  render={() => (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{ textTransform: 'capitalize', height: 45 }}
                      onClick={() => navigate('/planes')}
                      fullWidth
                    >
                      <Typography>
                        <Hidden lgDown={true} lgUp={false}>
                          Quiero{' '}
                        </Hidden>
                        acceso total
                      </Typography>
                    </Button>
                  )}
                  permission={RBACPermissions.CAN_SUBSCRIPTION}
                  error={() => null}
                />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} mdUp={false}>
        <Box>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography color="primary" fontWeight="bold">
                TU CONTENIDO
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route) => {
              return (
                <ListItem
                  divider
                  style={{ cursor: 'pointer' }}
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <ListItemText primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}
          </List>

          <List component="nav" aria-label="mailbox folders">
            <ListItem>
              <Typography fontWeight="bold">SOPORTE</Typography>
            </ListItem>
            <Divider />
            <a
              className={classes.helpCenter}
              style={{ textDecoration: 'none' }}
              target="_blank"
              href="/"
            >
              <ListItem divider>
                <ListItemText
                  style={{ fontSize: '16px' }}
                  primary="Centro de ayuda"
                />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
            </a>
          </List>
        </Box>
      </Hidden>
    </>
  );
};
